.veraButton {
  background: #006766 !important;
  border: 1px solid #006766 !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 4px !important;
  font-family: "Inter", sans-serif !important;
  text-transform: none !important;
  gap: 8px;
  padding: 10px 16px !important;
  max-height: 40px;
  line-height: 1.25 !important;
  &.right,
  &:global(.right) {
    flex-direction: row-reverse;
  }
  :global(.MuiSvgIcon-root) {
    width: 20px;
    height: 20px;
  }
  &:hover {
    background: #005a59 !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  }
  &:global(.MuiButton-outlined) {
    background: #fff !important;
    border-color: #ff1d1d !important;
    color: #ff1d1d !important;
  }
  &:global(.MuiButton-text) {
    background: #fff !important;
    border: none !important;
    color: #008284 !important;
    padding: 2px 5px !important;
    min-width: fit-content !important;
    box-shadow: none !important;
  }
  &.danger {
    background: #d61010 !important;
    border-color: #d61010 !important;
    &:hover {
      background: #b60c0c !important;
    }
  }
}
