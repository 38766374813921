.createNewPassword-wrapper {
  min-height: 100vh;

  padding: 20px 30px;
  background-color: #f5f5f5;
  border-radius: 4px;

  .createNewPassword-screen-top-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .createNewPassword-screen-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 82px 80px;
    // min-height: 80%;
    @media (max-width: 700px) {
      padding: 82px 18px;
    }
    .createNewPassword-form-wrapper {
      display: flex;
      flex-direction: column;

      @media (max-width: 700px) {
        width: 100%;
      }

      width: 50%;
      .createNewPassword-input-email-disabled {
        background-color: #f5f5f5;
        font-weight: 600;
        .Mui-disabled {
          font-weight: bolder;
        }
      }
      // height: 100%;
      .createNewPassword-heading {
        color: #10888b;
        font-family: "Tomato Grotesk";
        font-weight: 600;
      }
      .createNewPassword-form-button {
        background: #006766;
        border: 1px solid #006766;
        /* Shadow/xs */

        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 4px;
        text-transform: capitalize;
      }
      .last-text {
        color: #667085;

        font-weight: 400;
        text-align: center;
        span {
          color: #008284;

          font-weight: bolder;
          cursor: pointer;
        }
      }
      .google-createNewPassword-button {
        background-color: #fff;
        color: #344054;

        border: 1px solid #d0d5dd;
        /* Shadow/xs */

        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;

        font-weight: bolder;
        text-transform: capitalize;
      }
      .google-login-button {
        background-color: #fff;
        color: #344054;

        border: 1px solid #d0d5dd;
        /* Shadow/xs */

        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;

        font-weight: bolder;
        text-transform: capitalize;
      }
      .google-signup-button {
        background-color: #fff;
        color: #344054;

        border: 1px solid #d0d5dd;
        /* Shadow/xs */

        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;

        font-weight: bolder;
        text-transform: capitalize;
      }
    }
  }
}
