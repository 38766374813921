.test{
  min-height: 100%;
min-width: 80%;
}
.setHeight{
  min-height: 100vh;
}
.setIconSize{
  height: 4vw;
}
.flex1{
  display: flex;
  justify-content: center;
  align-items: center;
}
.fit-to-screen{
  @media (min-width: 768px) and (max-height: 1080px) and (min-height: 660px) {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: 0 !important;
    h4{
      margin-top: 0 !important;
    }
    .feature-items-wrapper{
      margin-top: 10vh;
    }
  }
}
.feature-section-wrapper {
  // padding-top: 80px;

  .feature-heading {
    text-align: center;
    font-family: "Tomato Grotesk";
    font-weight: 600;
    font-size: clamp(24px, 2.4vw, 2.4vw);
  }
  .feature-items-wrapper {
    @media (min-width: 1280px) and (max-height: 740px) {
      margin-top: 10px;
    }
    .feature-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 33px;
      border: 2px solid transparent;  
      @media (min-width: 1280px) and (max-height: 740px) {
        padding-bottom: 20px;
      }
      .feature-item-heading {
        // font-family: "Inter";
        font-weight: 600;
        text-align: center;
        font-size: clamp(18px, 1.2vw, 1.2vw);
      }
      .feature-item-para {
        font-family: "Inter";
        margin-top: 16px;
        font-weight: 400;
        color: #667085;
        text-align: center;
        font-size: clamp(16px, 1vw, 1vw);

      }
      &:hover {
        background: #f7ffff;
        border: 2px solid #006766;
        border-radius: 16px;
        cursor: pointer;
      }
    }
  }
}

.no-wrap{
  white-space: nowrap;
}
