.test {
  min-height: 100%;
  min-width: 80%;
}
.setIconSize {
  height: 4vw;
}
.flex1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fit-to-screen1 {
  min-height: 88vh;
  display: flex;
  justify-content: center;
  
  @media (min-width: 768px) and (max-height: 1080px) and (min-height: 660px) {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    // min-height: 100vh;
    padding: 0 !important;
    h4 {
      margin-top: 0 !important;
    }
    .feature-items-wrapper {
    //   margin-top: 10vh;
    }
  }
}

.feature-section-wrapper1 {

  .feature-heading {
    text-align: center;
    font-family: "Tomato Grotesk";
    font-weight: 600;
    font-size: clamp(24px, 2.4vw, 2.4vw);
  }
  .feature-items-wrapper {
    @media (min-width: 1280px) and (max-height: 740px) {
      margin-top: 10px;
    }
    display: flex;
    justify-content: space-between;
    .featureItem {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 33px;
        width: calc(33.33% - 20px); 


      @media (min-width: 1280px) and (max-height: 740px) {
        padding-bottom: 20px;
      }
      .feature-item-heading {
        // font-family: "Inter";
        font-weight: 600;
        text-align: center;
        font-size: clamp(18px, 1.1vw, 1.2vw);
        white-space: normal;
      }
      .feature-item-heading-affiliate {
        // font-family: "Inter";
        font-weight: 600;
        text-align: center;
        color:grey;
        font-size: clamp(18px, 1vw, 1.2vw);
        white-space: normal;
      }
      .feature-item-para {
        font-family: "Inter";
        margin-top: 16px;
        font-weight: 400;
        color: #667085;
        text-align: center;
        font-size: clamp(16px, 1vw, 1vw);
      }
    }
  }
}

.no-wrap {
  white-space: nowrap;
}
