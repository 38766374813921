.pageWrapper {
  max-width: unset !important;
  padding: 32px 80px !important;
  max-height: calc(100vh - 73px);
  overflow: auto;
  @media (max-width: 1366px) {
    padding: 32px 50px !important;
  }

  .taskBtnWrapper {
    display: flex;
    justify-content: flex-end;
  }

  .sectionHeader {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;
    font-weight: 600;
    font-size: 14px;
    &.mb32 {
      margin-bottom: 32px;
    }
  }
  .pageTitle {
    font-family: "Tomato Grotesk", sans-serif;
    font-weight: 500;
    font-size: 30px;
    line-height: 1.2;
    color: #008284;
    margin-top: 0;
    margin-bottom: 0px;
  }
  .pageSubtitle {
    font-family: "Inter", sans-serif;
    font-size: 24px;
    line-height: 1.2;
    color: #101828;
    margin: 0 0 32px;
  }
  .searchBar {
    min-width: 320px;
    margin-left: auto;
    input {
      height: 44px;
      padding-block: 0;
    }
    :global(.MuiOutlinedInput-notchedOutline) {
      border: 1px solid #d0d5dd !important;
      border-radius: 4px;
    }
  }
}
.pageWrapperAdmin {
  max-width: unset !important;
  padding: 32px 80px !important;
  max-height: calc(100vh - 73px);
  overflow: auto;

  @media (max-width: 1366px) {
    padding: 32px 50px !important;
  }

  .taskBtnWrapper {
    display: flex;
    justify-content: flex-end;
  }

  .sectionHeader {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;
    font-weight: 600;
    font-size: 14px;
    padding-right: 14px;
    &.mb32 {
      margin-bottom: 32px;
    }
  }
  .pageTitle {
    font-family: "Tomato Grotesk", sans-serif;
    font-weight: 500;
    font-size: 30px;
    line-height: 1.2;
    color: #008284;
    margin-top: 0;
    margin-bottom: 0px;
  }
  .pageSubtitle {
    font-family: "Inter", sans-serif;
    font-size: 24px;
    line-height: 1.2;
    color: #101828;
    margin: 0 0 32px;
  }
  .searchBar {
    min-width: 320px;
    input {
      height: 44px;
      padding-block: 0;
    }
    :global(.MuiOutlinedInput-notchedOutline) {
      border: 1px solid #d0d5dd !important;
      border-radius: 4px;
    }
  }
}

.adminPad_o {
  padding: 0px !important;
  margin-top: 100px;
}

.adminSideBarItemActive {
  background: #008284;
  color: white;
}
