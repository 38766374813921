.process-explainer-main-box {
  position: relative;
  padding-top: 32px;
  min-width: 80%;
  padding-bottom: 5rem;
  @media (min-width: 768px) and (max-height: 1080px) {
    min-height: 100vh;
  }
  @media (min-width: 768px) {
    padding-top: 80px;
  }

  .line-pattern-process-explainer {
    position: absolute;
    left: 104px;
    bottom: 0;
    transform: scale(1.3);
    z-index: -1;

    @media (min-width: 280px) and (max-width: 359px) {
      width: 50%;
      height: 70%;
      bottom: 0%;
      top: 2%;
      left: 25%;
    }

    @media (max-width: 360px) {
      width: 80%;
      top: 0%;
    }

    @media (min-width: 361px) and (max-width: 376px) {
      width: 80%;
      bottom: 0%;
      top: 2%;
      left: 25%;
    }

    @media (max-width: 500px) {
      left: -43px;
      transform: scale(0.7);
      top: -120px;
    }

    @media (min-width: 501px) and (max-width: 768px) {
      left: 44px;
      transform: scale(1);
      top: 4px;
    }

    // @media (max-width: 1200px) {
    //   bottom: 5%;

    //   transform: scale(0.9);
    // }

    @media (min-width: 1201px) and (max-width: 1326px) {
      width: 80%;
      bottom: 0%;
      top: 12%;
      left: 20%;
    }

    @media (min-width: 1550px) {
      // left: 14%;
    }
    @media (min-width: 1850px) {
      // left: 11%;
      // transform: scale(2.2);
    }

    @media (min-width: 2100px) {
      // left: 20%;
    }
  }

  .process-explainer-wrapper {
    padding: 15px;
    position: relative;
    min-width: 80%;

    .col-reverse {
      @media (max-width: 500px) {
        flex-direction: column-reverse;
      }
    }

    .process-explainer-item-img-box {
      position: relative;

      @media (max-width: 500px) {
        margin-top: 30px;
      }

      .process-explainer-item-video {
        // position: absolute;
        // bottom: 20px;
        padding-left: 144px;
        position: relative;
        margin-top: 30px;
        @media (max-width: 500px) {
          margin-top: 60px;
          margin-bottom: 40px;
          padding-left: 10px;
          width: 250px;
        }
        span {
          position: absolute;
          top: 50%;
          display: block;
          right: 35%;
          @media (max-width: 500px) {
            right: 19%;
            top: 49%;
          }
          z-index: 1111111;
          svg {
            font-size: 74px;
          }

          &:hover {
            span {
              display: block;
            }
          }
        }
        video {
          aspect-ratio: 1/1;
          object-fit: cover;
          /* height: auto; */
          width: 100%;
          box-shadow: 1px 3px 20px 0px;

          @media (max-width: 500px) {
            width: 130%;
            margin-left: 10px;
          }

          border-radius: 0px 200px 0px 0px;
        }
      }
    }
    .process-explainer-item-boxes {
      .process-explainer-item-box-active {
        border: 2px solid #66b1b4;

        box-shadow:
          0px 10px 15px -3px rgba(16, 24, 40, 0.1),
          0px 4px 6px -4px rgba(16, 24, 40, 0.1);
        border-radius: 12px;
        padding: 15px;

        .sub-step {
          font-weight: 500;
        }
        .step-para {
          font-weight: 400;
          color: #667085;
        }
      }
      .process-explainer-item-box {
        border: 1px solid #e5e7eb;
        background-color: #fff;
        /* Drop shadow/Small */

        box-shadow:
          0px 1px 3px rgba(16, 24, 40, 0.1),
          0px 1px 2px -1px rgba(16, 24, 40, 0.1);
        border-radius: 12px;

        @media (min-width: 1700px) {
          padding: 36px;
        }
        @media (min-width: 1800px) and (max-height: 905px) {
          padding: 28px;
        }
        padding: 15px;

        .step-head {
          font-weight: 600;
          font-size: clamp(14px, 0.9vw, 0.9vw);
        }
        .sub-step {
          font-weight: 600;
          font-size: clamp(18px, 1.2vw, 1.2vw);
          line-height: 1.1;
        }
        .step-para {
          font-weight: 400;
          color: #667085;
          font-size: clamp(16px, 1vw, 1vw);
        }
      }
    }
  }
}
