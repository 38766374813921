#blogBody {
  background-image: url("../../../assets/blog/Background.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: -70px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ContainerStyle {
  width: 300px;
  height: 400px;
  margin: 0.5rem;
}
