.device-img {
  max-height: 85%;
  max-width: 85%;
}

.setWidthMCS {
  min-width: 80%;
}

.manage-case-section-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0px;
  flex-direction: column;
  .manage-heading {
    font-family: "Tomato Grotesk";
    font-weight: 600;
    text-align: center;
    font-size: clamp(24px, 2.2vw, 2.2vw);
  }
  .manage-para {
    font-family: "Inter";
    font-weight: 400;
    color: #667085;
    text-align: center;
    margin: 20px 0px;
    font-size: clamp(16px, 1.1vw, 1.1vw);
  }
  .manage-case-section-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 60px 0px;

    .manage-case-section-img-item {
      @media (max-width: 500px) {
        display: none;
      }

      display: flex;
      justify-content: center;
      position: relative;
      .device-img {
        display: flex;
        justify-content: center;
        position: relative;

        img.mobile-img {
          width: 18%;
          height: 80%;
          position: absolute;
          right: 125px;
          bottom: -26px;
        }
        img.tab-img {
          width: 60%;
        }
      }
      svg.line-pattern-right-top {
        position: absolute;
        right: 120px;
        top: -50px;
        z-index: -1;
        transform: rotate(180deg);
      }
      svg.line-pattern-left-bottom {
        position: absolute;
        bottom: -50px;
        left: 124px;
        z-index: -1;
      }
    }
    .manage-case-section-img-item-mob {
      display: flex;
      justify-content: center;
      position: relative;
      align-items: center;
      .line-pattern {
        position: absolute;
        bottom: -47px;
        left: 0;
        z-index: -1;
      }
      img {
        width: 85%;
      }
      @media (min-width: 500px) {
        display: none;
      }
    }
  }
}
