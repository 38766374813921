.setWidth {
  min-height: 100%;
  bottom: 0;
  min-width: 80%;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-box-wrapper1 {
  min-height: 100vh;
  position: relative;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)), url('../../assets/LandingPage/heroCoverImage.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (min-width: 768px) {
    margin-bottom: 5rem;
  }
  .line-pattern {
    position: absolute;
    right: 0;
    transform: scale(1.3);
    top: 28px;

    @media (max-width: 281px) {
      width: 50%;
      height: auto;
      margin-right: 60px;
    }

    @media (min-width: 282px) and (max-width: 359px) {
      width: 50%;
      height: 70%;
      bottom: 0%;
      top: 2%;
      left: 25%;
    }

    @media (max-width: 360px) {
      width: 80%;
      top: 0%;
    }

    @media (max-width: 414px) {
      width: 80%;
      top: 0%;
      left: 33%;
    }

    @media (min-width: 361px) and (max-width: 376px) {
      width: 80%;
      bottom: 0%;
      top: 2%;
      left: 31%;
    }

    @media (min-width: 377px) and (max-width: 600px) {
      right: -11%;
      bottom: 10%;
      top: unset;
      transform: scale(1);
    }

    @media (min-width: 600px) and (max-width: 1200px) {
      right: -11%;
      bottom: 16%;
      top: unset;
      transform: scale(1);
    }
  }
  .hero-section-wrapper {
    overflow: hidden;
    position: relative;

    .hero-item-wrapper {
      display: flex;
      margin: 70px 0px;
      justify-content: left;
      align-items: center;
      position: relative;
      margin-top: 133px;
      z-index: 111111;
      @media (min-width: 768px) and (max-height: 1080px) {
        min-height: calc(100vh - 230px);
      }

      .hero-item-img {
        svg {
          width: 100%;
          height: 100%;
        }
      }

      .hero-btn-wrapper {
        display: flex;
        flex-direction: column;
        @media (min-width: 500px) {
          display: none;
        }
        margin-bottom: 77px;
        .hero-btn-getstarted {
          background: #006766;
          border: 1px solid #00424e;
          text-transform: capitalize;
          margin-bottom: 10px;
          padding: 10px 20px;

          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
        }
        .hero-btn-login {
          background: #ffffff;
          text-transform: capitalize;
          border: 1px solid #d0d5dd;
          padding: 10px 20px;
          color: #344054;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
        }
      }
    }

    .hero-heading {
      color: #008083;
      font-weight: 600;
      font-family: "Tomato Grotesk";
      font-size: 4rem;
    }

    .hero-para {
      padding-block: 10px;
      color: #667085;
      font-family: "Inter";
      font-size: clamp(16px, 1.2vw, 1.2vw);

      @media (max-width: 500px) {
        width: 100%;
      }
      width: 70%;
    }
  }
}

