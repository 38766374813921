body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Tomato Grotesk", sans-serif;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.customHeaderStyle
  .MuiDataGrid-columnHeaderDraggableContainer
  .MuiDataGrid-columnHeaderTitleContainer {
  display: flex;
  justify-content: flex-end;
}
.d-none-footer > .MuiDataGrid-footerContainer {
  display: none !important;
}
