.profileWrapper {
  background: #ecfdf3;
  border-radius: 5px;
  padding: 20px 28px;
  margin-top: 26px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  gap: 20px;
  .profile {
    // border: 2px solid #008083;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    object-fit: cover;
  }
  .info {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
    label {
      font-weight: 500;
      display: block;
      color: #000;
    }
  }
}
// tasks
.taskList {
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  margin-bottom: 50px !important;
  p {
    width: 50%;
  }
  h4 {
    margin: 0 0 16px;
    font-weight: 500;
    font-family: "Inter";
    color: #4c5858;
  }
  :global(.MuiFormGroup-root) {
    color: #737373;
  }
  :global(.MuiChip-root) {
    color: #006766;
    width: 100%;
    background: #b1d7d8;
    max-width: 179px;
  }
}
// documents
.documents {
  h4 {
    margin-bottom: 0;
    font-family: "Tomato Grotesk", sans-serif;
    font-size: 16px;
  }
}
.uploadedDoc {
  h5 {
    font-size: 16px;
    color: #676767;
    margin-bottom: 5px;
  }
  .item {
    background: #ffffff;
    border: 2px solid #e0e2e7;
    border-radius: 6px;
    padding: 8px;
    padding-left: 12px;
    width: 100%;
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #0f0f0f;
    font-weight: 400;
  }
  .itemDiv {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    label {
      font-weight: 500;
      display: block;
      font-family: "Tomato Grotesk", sans-serif;
      font-size: 16px;
      cursor: pointer;
      color: #000;
    }
    .uploaded_at {
      font-weight: normal;
      white-space: wrap;
      overflow: hidden;
      font-size: 16px;
      line-height: 24px;
      color: #667085;
    }
  }
}
// Upload Modal
.uploadModal {
  .inputFileUpload {
    display: none;
  }
  :global(.MuiDialog-paper) {
    padding: 32px 56px;
  }
  :global(.MuiDialogContent-root) {
    padding-inline: 0;
    font-family: "Inter", sans-serif;
  }
  :global(.MuiDialogTitle-root) {
    font-family: "Tomato Grotesk", sans-serif;
    color: #0f0f0f;
    font-weight: 600;
    font-size: 24px;
    padding: 0;
    margin-bottom: 32px;
  }
  .uploadWrapper {
    background: #ecfdf3;
    border: 2px dashed #b2cff1;
    border-radius: 4px;
    padding: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
      margin-bottom: 28px;
    }
    h5 {
      font-family: "Inter", sans-serif;
      font-weight: 700;
      font-size: 17px;
      color: #0f0f0f;
      margin: 0 0 10px;
      u {
        color: #008284;
        cursor: pointer;
      }
    }
    p {
      font-size: 13px;
      color: #676767;
      margin: 0;
    }
  }
  :global(.MuiDialogActions-root) {
    padding: 0;
    margin-top: 12px;
    button {
      width: 100%;
      background: #008284;
    }
  }
}

.fileImage {
  display: inline;
  width: auto;
  height: 30px;
  margin: 10px;
  vertical-align: center;
}

.Container h4 {
  color: #4c5858;
}
